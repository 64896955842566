

























import { Component, Vue } from "vue-property-decorator";
import { Locales } from "../locales/locales";

@Component
export default class Language extends Vue {
  languages = Locales;

  get currentLanguageName() {
    return this.languages.filter((x) => x.value === this.$i18n.locale)[0].text;
  }

  changeLanguage(val: string) {
    this.$i18n.locale = val;
  }
}
