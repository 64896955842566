
































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import termsAndPolicyMessages from "../locales/termsAndPolicy";

@Component({
  i18n: {
    messages: termsAndPolicyMessages,
  },
})
export default class PrivacyPolicyPage extends Vue {
  scrollToTop() {
    window.scrollTo(0, 0);
  }
}
