export default {
    en: {
        title: "Join the action",
        subtitle: `
            Please fill in the form below to sign up.
            You will receive an email with your credentials to login to the meal vouchers action platform.
        `,

        form: {
            // fieldsMarkedAreRequired: "All fields marked with * are required",
            fieldIsRequired: "This field is required",
            checkIsRequired: "You must confirm in order to sign up",
            preferNotToAnswer: "I prefer not to answer",

            subheader: {
                representative: "LEGAL REPRESENTATIVE"
            },

            info: {
                // legalEntityName: "Legal Entity Name",
                // numEstablishments: "Number of corresponding establishments",
                // businessAddress: "Business Address",
                // postalCode: "Postal Code",
                // municipality: "Municipality",
                companyName: "Company Name",
                sirenNumber: "RCS number / SIREN number",
                sirenFormat: "9 digit number",
                multipleCompaniesNote: `
                    Note: If you represent multiple companies, please provide the information regarding one of them.
                    You will be able to register all the companies you represent on the platform.`,
                isRestoPartOfGroup: "My establishment(s) is (or are) part of a catering group or a franchise network",
                ifYesNameOfGroup: "If yes, name of the brand/group:",
                isMemberOfProfessionOrganization: "I am a member of a professional organization, union, federation or group of professionals",
                ifYesNameOfOrganization: "If yes, name of the organization/union/federation:",
                lastName: "Last Name",
                firstName: "First Name",
                telephone: "Telephone",
                email: "Email",
                confirmAuthorized: "I declare that I am duly authorized to represent the company or companies that I will register.",
                // validatePostalCodeError: "Please enter a valid postal code",
                validateEmailError: "Please enter a valid email address",
                // validateNumEstablishmentsError: "Please enter a valid number",
            }
        },
        agreement: {
            checkboxLabel: `I agree to the following:`,
            informativeParagraph: `
                The processing of the data filled in this form is necessary to my application for the service and its execution.
                TransAtlantis may also be legally required to process the data as part of the fight against money laundering.
                The data may be transferred to the USA in order to examine my application for registration and will be kept for 5 years after the end of the service.
                I have the right to request access, rectification, portability,
                deletion and to define the post-mortem fate of my data but also the limitation or opposition to the processing of these data,
                at <a href="mailto:contactTR@TransAtlantis.com">contactTR@TransAtlantis.com</a>. I can at any time submit a complaint to the CNIL.
                For more information on the processing of my data, I can consult the
            `,
            privacyPolicy: "privacy policy",
        },

        signup: "Sign Up",
        signupDialog: {
            title: {
                success: "Thank you for your registration",
                error: "Error"
            },
            text: {
                success: "A confirmation email including your login credentials will be sent to the following email shortly:",
                DuplicateUserName: "The following username already exists:"
            }
        }
    },
    fr: {
        title: "Rejoignez l’action",
        subtitle: `
            Veuillez remplir le formulaire ci-dessous pour vous inscrire.
            Vous recevrez un e-mail avec vos identifiants pour vous connecter à la plateforme de l’action titres-restaurant.
        `,

        form: {
            // fieldsMarkedAreRequired: "Tous les champs marqués d’un * sont obligatoires",
            fieldIsRequired: "Ce champ est requis.",
            checkIsRequired: "Vous devez confirmer pour vous inscrire",
            preferNotToAnswer: "Je préfère ne pas répondre",

            subheader: {
                representative: "REPRESENTANT LEGAL"
            },

            info: {
                // legalEntityName: "Nom de l’entité juridique",
                // numEstablishments: "Nombre d’établissements au sein de l’entité juridique",
                // businessAddress: "Adresse du siège social",
                // postalCode: "Code postal",
                // municipality: "Nom de la commune",
                companyName: "Nom de société",
                sirenNumber: "Numéro RCS / SIREN",
                sirenFormat: "Nombre à 9 chiffres",
                multipleCompaniesNote: `
                    A noter : si vous représentez plusieurs sociétés, veuillez fournir les informations relatives à l’une d’entre elles.
                    Vous aurez la possibilité d’enregistrer toutes les sociétés que vous représentez sur la plateforme.`,
                isRestoPartOfGroup: "Mon (ou mes) établissement(s) fait (ou font) partie d’un groupe de restauration ou d’un réseau de franchise",
                ifYesNameOfGroup: "Si oui, nom de l’enseigne/groupe :",
                isMemberOfProfessionOrganization: "Je suis adhérent à une organisation professionnelle, syndicat, fédération ou groupement de professionnels",
                ifYesNameOfOrganization: "Si oui, nom de l’organisation/syndicat/fédération :",
                lastName: "Nom",
                firstName: "Prénom",
                telephone: "Tél",
                email: "Courriel",
                confirmAuthorized: "Je déclare être dûment habilité à représenter la ou les sociétés que je vais enregistrer.",
                // validatePostalCodeError: "Veuillez entrer un code postal valide",
                validateEmailError: "Veuillez entrer une adresse email valide",
                // validateNumEstablishmentsError: "Veuillez entrer un nombre valide",
            }
        },
        agreement: {
            checkboxLabel: `J’accepte les conditions suivantes :`,
            informativeParagraph: `
                Le traitement des données renseignées dans le présent formulaire est indispensable à ma demande d’adhésion au service et à son exécution.
                TransAtlantis peut également être légalement tenu de traiter ces données dans le cadre de la lutte contre le blanchiment d’argent.
                Elles sont susceptibles d’être transférées vers les USA aux fins d’examen de ma demande d’adhésion et sont conservées 5 ans à compter de la fin dudit service.
                Je dispose du droit de demander l’accès, la rectification, la portabilité,
                l’effacement et de définir le sort post mortem de mes données mais également la limitation ou l’opposition au traitement de celles-ci,
                à l’adresse <a href="mailto:contactTR@TransAtlantis.com">contactTR@TransAtlantis.com</a>. Je peux introduire à tout moment une réclamation auprès de la CNIL.
                Pour plus d’information sur le traitement de mes données, je peux consulter la
            `,
            privacyPolicy: "politique de confidentialité",
        },

        signup: "Inscrivez-vous",
        signupDialog: {
            title: {
                success: "Merci pour votre inscription",
                error: "Erreur"
            },
            text: {
                success: "Un e-mail de confirmation contenant vos identifiants de connexion sera envoyé à l’adresse suivante :",
                DuplicateUserName: "Le nom d’utilisateur suivant existe déjà :"
            }
        }
    }
};