






































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import InfoFormModel from "../models/InfoFormModel";
import InfoFormMessages from "../locales/InfoForm";
import axios from "axios";

@Component({
  i18n: {
    messages: InfoFormMessages,
  },
})
export default class InfoForm extends Vue {
  @Prop({ required: true }) private model!: InfoFormModel;

  private valid = false;

  private dialog = false;

  private authorized = false;

  private agreement = false;

  private password = "";

  private loading = false;

  private registerSucceeded = false;

  private errorCode = "";

  private get signupDialogTitle() {
    if (this.registerSucceeded) {
      return this.$t("signupDialog.title.success");
    }
    if (this.errorCode == "DuplicateUserName") {
      return this.$t("signupDialog.title.error");
    }
  }

  private get signupDialogText() {
    if (this.registerSucceeded) {
      return this.$t("signupDialog.text.success");
    }
    if (this.errorCode == "DuplicateUserName") {
      return this.$t("signupDialog.text.DuplicateUserName");
    }
  }

  onSignup() {
    this.loading = true;
    this.model.registrationUrl = window.location.href;
    axios
      .post("/Registration/Register", this.model)
      .then((resp) => {
        this.registerSucceeded = true;
        this.password = resp.data;
        this.$gtag.event("sign_up", { method: "Registration App" });
      })
      .catch((error) => {
        this.registerSucceeded = false;
        this.errorCode = error.response.data;
      })
      .then(() => {
        this.dialog = true;
        this.loading = false;
      });
  }
}
