export default class InfoFormModel {

    // public legalEntityName = ""
    // public numEstablishments = ""
    // public businessAddress = ""
    // public postalCode = ""
    // public municipality = ""

    public companyName = ""
    public sirenNumber = ""
    public isRestoPartOfGroup = false
    public restoGroupName = ""
    public isMemberOfProfessionOrganization = false
    public professionOrganization = ""
    public professionOrganizationNotAnswered = false
    public lastName = ""
    public firstName = ""
    public email = ""
    public telephone = ""
    public registrationUrl = ""

    // public isPostalCodeValid(): boolean {
    //     const regexp = /^[0-9]{5}$/;
    //     return regexp.test(this.postalCode);
    // }

    isEmailValid(): boolean {
        const regexp = /.+@.+\..+/
        return regexp.test(this.email);
    }

    // isNumEstablishmentsValid(): boolean {
    //     const regexp = /^[1-9][0-9]*$/
    //     return !this.numEstablishments || regexp.test(this.numEstablishments)
    // }

}